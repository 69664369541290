import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import { Box, Button, Container, Typography } from '@mui/material';
// Styles
import useStyles from './styles';
import { setSnackbar } from 'redux/actions/snackbar';
import { ContentCopy } from '@mui/icons-material';

export default function RefLinks() {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));
    const dispatch = useDispatch();

    function handleCopy(link) {
        navigator.clipboard.writeText(link);
        dispatch(setSnackbar({ severity: 'success', message: 'Link copied!' }));
    }

    const data = {
        referral_code: user?.ReferralPartner?.code,
        type: 'self-employment',
        path: '/direct-booking',
        environment: 'Production',
    };
    const dataERC = {
        referral_code: user?.ReferralPartner?.code,
        type: 'ERC',
        path: '/direct-booking',
        environment: 'Production',
    };
    const encodedCode = encodeURIComponent(user?.ReferralPartner?.code);
    let directBooking = `https://calendly.com/the-relief-consultants/self-employment-sick-family-leave-credit?referral_code=${encodedCode}&utm_source=referral-partner&salesforce_uuid=${encodeURI(
        JSON.stringify(data),
    )}`;
    let directBookingERC = `https://calendly.com/the-relief-consultants/shutdown-credit?referral_code=${encodedCode}&utm_source=referral-partner&salesforce_uuid=${encodeURI(
        JSON.stringify(dataERC),
    )}`;
    const ercFunnelLink1 = `https://thereliefconsultants.com/get-your-erc?referral_code=${encodedCode}&utm_source=referral-partner`;
    const ercFunnelLink2 = `https://thereliefconsultants.com/get-your-erc-d?referral_code=${encodedCode}&utm_source=referral-partner`;
    const ercFunnelLink3 = `https://go.thereliefconsultants.com/employee-retention-credit-2025?referral_code=${encodedCode}&utm_source=referral-partner`;
    const visaFunnelLink3 = `https://thereliefconsultants.com/vm?referral_code=${encodedCode}&utm_source=referral-partner`;
    const visaFunnelLink2 = `https://thereliefconsultants.com/vm-landing?referral_code=${encodedCode}&utm_source=referral-partner`;

    const referrerLink = `https://thereliefconsultants.com/become-a-preferred-partner?referral_code=${encodedCode}&utm_source=referral-partner`;
    const sickLeaveCreditLink = `https://sickleavecredit.com?referral_code=${encodedCode}&utm_source=referral-partner`;
    const sickLeaveCreditLink2 = `https://go.thereliefconsultants.com/self-employment-tax-credit-2025?referral_code=${encodedCode}&utm_source=referral-partner`;
    const dualLink = `https://go.thereliefconsultants.com/trc-decision?referral_code=${encodedCode}&utm_source=referral-partner`;

    return (
        <Box className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <Box className={classes.section} sx={{ mb: 2 }}>
                    <Box className={classes.sectionHeader}>
                        <Typography variant="h5" sx={{ fontWeight: '600' }}>
                            Referral Links <span className="underline">ERC - Employee Retention Credit</span>
                        </Typography>
                    </Box>
                    <Box className={classes.sectionBody}>
                        <Box className={classes.codeBox}>
                            <Typography variant="h5" sx={{ fontWeight: '500', marginTop: 2 }}>
                                Book an appointment for your lead:
                            </Typography>

                            <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                                <Typography component={'a'} className="link" target="_blank" href={directBookingERC}>
                                    Direct Calender Booking Link
                                </Typography>
                                <ContentCopy className={classes.copyContent} onClick={() => handleCopy(directBookingERC)} />
                            </Box>
                            <Typography variant="h5" sx={{ fontWeight: '500', marginTop: 2 }}>
                                Refer Leads to TRC Funnels:
                            </Typography>
                            <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                                <Typography component={'a'} className="link" target="_blank" href={ercFunnelLink1}>
                                    ERC Funnel 1
                                </Typography>
                                <ContentCopy className={classes.copyContent} onClick={() => handleCopy(ercFunnelLink1)} />
                            </Box>
                            <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                                <Typography component={'a'} className="link" target="_blank" href={ercFunnelLink2}>
                                    ERC Funnel 2
                                </Typography>
                                <ContentCopy className={classes.copyContent} onClick={() => handleCopy(ercFunnelLink2)} />
                            </Box>
                            <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                                <Typography component={'a'} className="link" target="_blank" href={ercFunnelLink3}>
                                    New Funnel
                                </Typography>
                                <ContentCopy className={classes.copyContent} onClick={() => handleCopy(ercFunnelLink3)} />
                            </Box>
                            <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                                <Typography component={'a'} className="link" target="_blank" href={dualLink}>
                                    Dual SETC/ERC Funnel
                                </Typography>
                                <ContentCopy className={classes.copyContent} onClick={() => handleCopy(dualLink)} />
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box className={classes.section} sx={{ mb: 2 }}>
                    <Box className={classes.sectionHeader}>
                        <Typography variant="h5" sx={{ fontWeight: '600' }}>
                            Referral Links <span className="underline">SETC - Self Employment Tax Credit</span>
                        </Typography>
                    </Box>
                    <Box className={classes.sectionBody}>
                        <Box className={classes.codeBox}>
                            <Typography variant="h5" sx={{ fontWeight: '500' }}>
                                Refer Leads to the SETC program:
                            </Typography>
                            <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                                <Typography className="link" component={'a'} target="_blank" href={sickLeaveCreditLink}>
                                    Self Employment Sick Leave Credit Link
                                </Typography>
                                <ContentCopy className={classes.copyContent} onClick={() => handleCopy(sickLeaveCreditLink)} />
                            </Box>
                            <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                                <Typography className="link" component={'a'} target="_blank" href={sickLeaveCreditLink2}>
                                    New Funnel
                                </Typography>
                                <ContentCopy className={classes.copyContent} onClick={() => handleCopy(sickLeaveCreditLink2)} />
                            </Box>
                            <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                                <Typography component={'a'} className="link" target="_blank" href={dualLink}>
                                    Dual SETC/ERC Funnel
                                </Typography>
                                <ContentCopy className={classes.copyContent} onClick={() => handleCopy(dualLink)} />
                            </Box>

                            <Typography variant="h5" sx={{ fontWeight: '500', marginTop: 2 }}>
                                Book an appointment for your lead:
                            </Typography>

                            <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                                <Typography component={'a'} className="link" target="_blank" href={directBooking}>
                                    Direct Calender Booking Link
                                </Typography>
                                <ContentCopy className={classes.copyContent} onClick={() => handleCopy(directBooking)} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.section}>
                    <Box className={classes.sectionHeader}>
                        <Typography variant="h5" sx={{ fontWeight: '600' }}>
                            Referral Links <span className="underline">Downsteam Sub RP's</span> (For SETC & ERC)
                        </Typography>
                    </Box>
                    <Box className={classes.sectionBody}>
                        <Box className={classes.codeBox}>
                            <Typography variant="h5" sx={{ fontWeight: '500', marginTop: 2 }}>
                                Refer a "Sub Referral Partner" with this link:
                            </Typography>
                            <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                                <Typography className="link" component={'a'} target="_blank" href={referrerLink}>
                                    Sub Referral Partner Referral Link
                                </Typography>
                                <ContentCopy className={classes.copyContent} onClick={() => handleCopy(referrerLink)} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.section} sx={{ mb: 2 }}>
                    <Box className={classes.sectionHeader}>
                        <Typography variant="h5" sx={{ fontWeight: '600' }}>
                            Referral Links <span className="underline">Visa Mastercard Settlement</span>
                        </Typography>
                    </Box>
                    <Box className={classes.sectionBody}>
                        <Box className={classes.codeBox}>
                            <Typography variant="h5" sx={{ fontWeight: '500', marginTop: 2 }}>
                                Refer Leads to Visa Mastercard Funnels:
                            </Typography>
                            <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                                <Typography component={'a'} className="link" target="_blank" href={visaFunnelLink2}>
                                    Visa Mastercard Form Funnel
                                </Typography>
                                <ContentCopy className={classes.copyContent} onClick={() => handleCopy(visaFunnelLink2)} />
                            </Box>
                            <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                                <Typography component={'a'} className="link" target="_blank" href={visaFunnelLink3}>
                                    Visa Mastercard Direct to Form
                                </Typography>
                                <ContentCopy className={classes.copyContent} onClick={() => handleCopy(visaFunnelLink3)} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {/* <Card sx={{ mt: 2 }} cardOnly className={classes.inspiration}>
                    <Typography variant="h2" sx={{ mb: 2, fontFamily: 'Times New Roman' }}>
                        "Life is a gift, and it offers us the privilege, opportunity, and responsibility to give something back by becoming
                        more."
                    </Typography>
                    <Typography variant="h2" sx={{ fontFamily: 'Times New Roman' }}>
                        -Tony Robbins
                    </Typography>
                </Card> */}
            </Container>
        </Box>
    );
}
